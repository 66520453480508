/* You can add global styles to this file, and also import other style files */
* {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100vh;
  overflow:hidden;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', 'Arial',sans-serif;
}
.margin-top {
  margin-top: 16px;
}
.horizontal-padding {
  margin-left: 16px;
  margin-right: 16px;
}
.flex-spacer {
  flex: 1 1 auto;
}

.main-router-outlet + * {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.expand-router + * {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.idsr-hint-text {
  color: #aaa;
  font-size: 0.8em;
  font-style: italic
}

.idsr-hint-text::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.15), rgba(255,255,255,0));
}


.idsr-chip {
  display: inline-block;
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: smaller;
  line-height: 0.8;
  border-radius: 16px;
  border: 1px solid;
  border-color: #9e9e9e;
  background-color: #e0e0e0;
  cursor:default;
}

.date-has-issue{
  border-style: solid !important;
  border-width: 2px !important;
  border-color: cornflowerblue !important;
  padding: 4px !important;
  border-radius: 100% !important;
}
